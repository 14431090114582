import RatingSummaryPreview from '@@/common/component/RatingSummaryEditor/preview';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Checkbox, Dropdown, Form, Select, Tooltip } from 'antd';
import CustomPrompt from '@@/common/component/CustomPrompt';
import { CaretDownOutlined } from '@ant-design/icons';
import { TALENT_INFORMATION_KEY } from '@@/common/constant/reviewee';
import { SUMMARY_MAX_LENGTH } from '@@/common/constant/review';
import TinyMceEditor from '@@/_new_src_/components/TinyMceEditor';
import { useDispatch, useSelector } from 'react-redux';
import { cycleDetailByVersion } from '@@/redux/slice/cycleDetailByVersionSlice';
import { selectLocaleResource } from '@@/redux/slice/globalSlice';
import perfModalConfirm from '@@/common/component/PerfModalConfirm';
import { updateReviewByReviewer } from '@@/api/review';
import perfMessage from '@@/common/component/PerfMessage/perfMessage';
import {
  selectAdditionalTalentInformation,
  selectIsCheckedGC,
  selectIsCheckedHP,
  selectIsCheckedNoTalent,
  selectSelectedRating,
  selectSummaryState,
  setIsCheckedGC,
  setIsCheckedHP,
  setIsCheckedNoTalent,
  setIsEditRatingSummary,
  setSelectedRating,
  setSummaryState,
} from '@@/redux/slice/revieweeSlice';
import { trigSurvey } from '@@/common/qualarooTrigger';
import { WRITE_ASSESSMENT } from '@@/common/constant/qualaroo';
import InformationIcon from '@@/assets/images/information.svg';
import cls from 'classnames';
import { get, isEmpty } from 'lodash';
import { TWER_I_SUPPORT_BY_PP_API } from '@@/common/constant/matomo';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import {
  CYCLE_SCORE_RATING,
  CYCLE_SCORE_RATING_DESCRIPTION,
  CYCLE_SCORE_RATING_DESCRIPTION_V1,
  CYCLE_SCORE_RATING_FOR_PROBATION,
  CYCLE_SCORE_RATING_V1,
  CYCLE_STATUS_ENUM,
  CYCLE_VERSION,
  NO_PERFORMANCE_INDICATOR,
} from '@@/_new_src_/constants/myCycles';
import {
  patchAssessmentByPPAsync,
  postUnmarkAssessmentNonEngagedByTbpAsync,
  putPPSaveAssessmentAsync,
} from '@@/_new_src_/store/cycleDetailSlice/asyncThunk';
import {
  ppSaveAssessment,
  resetSendRequestData,
  sendRequestData,
  setPpSaveAssessment,
} from '@@/_new_src_/store/cycleDetailSlice';
import { getRequestToEditStatus } from '@@/_new_src_/utils/feature/cycle';
import { useHistory, useLocation } from 'react-router-dom';
import nonEngagedCyclesLocale from '@@/_new_src_/local/nonEngagedCycles/en_US';
import { nonEngagedCycles } from '@@/_new_src_/store/nonEngagedCyclesSlice';
import ConfirmModal from '@@/_new_src_/components/ConfirmModal';
import {
  confirmModalType,
  setConfirmModalLoading,
  setConfirmModalStatus,
  setConfirmModalType,
} from '@@/_new_src_/store/commonSlice';
import commonLocale from '@@/_new_src_/local/myCycles/en_US';
import { CURRENT_PAGE, MENU_PAGE_LINK } from '@@/_new_src_/constants/pagePath';
import { PROBATION_STATUS } from '@@/_new_src_/constants/userEnum';
import { PP_SAVE_ASSESSMENT_VALUE } from '@@/_new_src_/constants/CycleStatus';
import LoadingWrapper from '@@/_new_src_/components/LoadingWrapper';
import IconButton from '@@/_new_src_/components/IconButton';
import { scrollToTop } from '@@/_new_src_/utils/feature';
import './index.less';

const { Option } = Select;

const RatingSummaryEditor = props => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { trackEvent } = useMatomo();
  const { pathname } = useLocation();
  const history = useHistory();

  const {
    nonEngagedCycles: {
      confirmModal: {
        buttonText: { cancelEdit, confirmEdit },
        submitTitle,
        submitText,
        cancelSubmit,
        confirmSubmit,
        successMessage: successMessageForPostAssessmentByTBP,
      },
    },
  } = nonEngagedCyclesLocale;

  const {
    cycleModal: {
      cancelEdit: {
        title: cancelTitle,
        content: cancelContent,
        cancelText: cancelEditText,
        okText: okEditText,
      },
    },
  } = commonLocale;

  const {
    editable,
    onEdit,
    isExpand = true,
    showEditIcon,
    isShowRequestEditButton,
    isShowDoneReviewEditButton,
    onOpenRequestEditModal,
    onSave,
    onCancel,
    isSubmitModalVisible,
  } = props;

  const {
    currentCycleDetail: {
      id: cycleId,
      cycleVersion,
      isNoneEngaged,
      isDoneCycle,
      isCalibratingCycle,
      isProbation,
      assessment,
      isPerformancePartner,
      isOpenCycle,
      isDraftCycle,
      lastUpdatedAt,
      assessmentNonEngaged,
      createBySystem,
      isInCommunicationStage,
    },
  } = useSelector(cycleDetailByVersion);

  const { isEditByTbp } = useSelector(nonEngagedCycles);
  const currentModalType = useSelector(confirmModalType);

  const localeResource = useSelector(selectLocaleResource);
  const {
    reviewList: reviewListLocale,
    reviewees: { ratingSummaryEditor: ratingSummaryEditorLocale },
    confirmCancelModal: confirmCancelModalLocale,
    confirmUndoModal: confirmUndoModalLocale,
  } = localeResource;

  const { content, okText, cancelText, successMessage } = ratingSummaryEditorLocale.confirmSubmit;

  const selectedRating = useSelector(selectSelectedRating);
  const summaryState = useSelector(selectSummaryState);
  const isCheckedHP = useSelector(selectIsCheckedHP);
  const isCheckedGC = useSelector(selectIsCheckedGC);
  const isCheckedNoTalent = useSelector(selectIsCheckedNoTalent);
  const additionalTalentInformation = useSelector(selectAdditionalTalentInformation);
  const { status, isAssessmentPatchSuccess, isAssessmentPostSuccess, isAssessmentPostLoading } =
    useSelector(sendRequestData);
  const {
    loading: ppSaveAssessmentLoading,
    success: ppSaveAssessmentSuccess,
    operationType,
  } = useSelector(ppSaveAssessment);
  const { isApprovedRequestToEdit } = getRequestToEditStatus(status);

  const [summaryTextInfo, setSummaryTextInfo] = useState(null);
  const [isSaveDraftLoading, setIsSaveDraftLoading] = useState(false);
  const [summaryInitialValue, setSummaryInitialValue] = useState(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const { isNonEngagedCyclesPage } = CURRENT_PAGE(pathname);

  const clearInputValue = () => {
    dispatch(setSummaryState(undefined));
    dispatch(setSelectedRating(undefined));
    dispatch(setIsCheckedHP(undefined));
    dispatch(setIsCheckedGC(undefined));
    dispatch(setIsCheckedNoTalent(undefined));
    setSummaryTextInfo(null);
  };

  const clearConfirmModal = () => {
    dispatch(setConfirmModalStatus(false));
    dispatch(setConfirmModalLoading(false));
    dispatch(setConfirmModalType(''));
    scrollToTop();
  };

  const isPpUpdateAssessment = useMemo(() => {
    return editable && isPerformancePartner && isCalibratingCycle && isInCommunicationStage;
  }, [editable, isPerformancePartner, isCalibratingCycle, isInCommunicationStage]);

  const cancelButtonText = () => {
    if (isNonEngagedCyclesPage) {
      return cancelEdit;
    } else if (isApprovedRequestToEdit) {
      return ratingSummaryEditorLocale.undo;
    } else {
      return ratingSummaryEditorLocale.cancel;
    }
  };

  const confirmButtonText = () => {
    if (isNonEngagedCyclesPage) {
      return confirmEdit;
    } else if (isDoneCycle) {
      return ratingSummaryEditorLocale.submitChanges;
    } else if (isPpUpdateAssessment) {
      return ratingSummaryEditorLocale.updateAssessmentButton;
    } else {
      return ratingSummaryEditorLocale.save;
    }
  };

  const confirmModalProps = useCallback(() => {
    if (currentModalType === 'cancelEditAssessmentNonEngaged') {
      return {
        title: cancelTitle,
        content: cancelContent,
        okText: okEditText,
        cancelText: cancelEditText,
        handleOkFunc: () => {
          dispatch(setIsEditRatingSummary(false));
          clearInputValue();
          clearConfirmModal();
        },
        handleCancelFunc: () => {
          clearConfirmModal();
        },
      };
    } else {
      return {
        title: submitTitle,
        content: submitText,
        okText: confirmSubmit,
        cancelText: cancelSubmit,
        handleOkFunc: async () => {
          if (isNonEngagedCyclesPage && isEditByTbp) {
            dispatch(setConfirmModalLoading(true));
            dispatch(
              postUnmarkAssessmentNonEngagedByTbpAsync({
                cycleId: cycleId,
                assessment: {
                  rating: selectedRating || assessment.rating,
                  summary: summaryState?.trim() || assessment.summary,
                  additionalTalentInformation: !isEmpty(additionalTalentInformation)
                    ? additionalTalentInformation
                    : assessment.additionalTalentInformation,
                  assessmentNonEngaged: assessment.assessmentNonEngaged,
                  lastUpdatedAt: lastUpdatedAt,
                },
              }),
            );
          }
        },
        handleCancelFunc: () => {
          clearConfirmModal();
        },
      };
    }
  }, [
    currentModalType,
    cycleVersion,
    cycleId,
    summaryState,
    selectedRating,
    lastUpdatedAt,
    additionalTalentInformation,
  ]);

  const showConfirmModal = useCallback(() => {
    return !(assessment?.rating === selectedRating && assessment?.summary === summaryState);
  }, [assessment, selectedRating, summaryState]);

  const ratingSelectedValue = useCallback(() => {
    if (isProbation) {
      return CYCLE_SCORE_RATING_FOR_PROBATION;
    }
    if (createBySystem) {
      if (assessmentNonEngaged) {
        return { ...CYCLE_SCORE_RATING, ...NO_PERFORMANCE_INDICATOR };
      }
      return CYCLE_SCORE_RATING;
    } else {
      if (assessmentNonEngaged) {
        return { ...CYCLE_SCORE_RATING_V1, ...NO_PERFORMANCE_INDICATOR };
      }
      return CYCLE_SCORE_RATING_V1;
    }
  }, [isProbation, assessmentNonEngaged]);

  const canSubmit = useCallback(() => {
    return (
      summaryTextInfo &&
      summaryTextInfo.trim() &&
      summaryTextInfo.length < SUMMARY_MAX_LENGTH + 1 &&
      selectedRating &&
      (isCheckedNoTalent || isCheckedHP || isCheckedGC)
    );
  }, [summaryTextInfo, selectedRating, isCheckedNoTalent, isCheckedHP, isCheckedGC]);

  const saveButtonProps = useCallback(() => {
    return canSubmit() ? { open: false } : {};
  }, [canSubmit()]);

  const setFieldsValue = () => {
    if (editable) {
      if (
        (selectedRating !== undefined || summaryState !== undefined) &&
        !isApprovedRequestToEdit
      ) {
        form.setFieldsValue({ rating: selectedRating, summary: summaryState });
        setSummaryInitialValue(summaryState);
      } else {
        form.setFieldsValue(assessment);
        dispatch(setSelectedRating(assessment?.rating));
        dispatch(setSummaryState(assessment?.summary));
        setSummaryInitialValue(assessment?.summary);
      }

      if (
        (isCheckedGC !== undefined ||
          isCheckedHP !== undefined ||
          isCheckedNoTalent !== undefined) &&
        !isApprovedRequestToEdit
      ) {
        return;
      }
      if (!isEmpty(assessment?.additionalTalentInformation)) {
        dispatch(
          setIsCheckedHP(
            assessment?.additionalTalentInformation.includes(TALENT_INFORMATION_KEY.HIGH_POTENTIAL),
          ),
        );
        dispatch(
          setIsCheckedGC(
            assessment?.additionalTalentInformation.includes(
              TALENT_INFORMATION_KEY.POTENTIAL_FOR_GRADE_CHANGE,
            ),
          ),
        );
        dispatch(
          setIsCheckedNoTalent(
            assessment?.additionalTalentInformation.includes(
              TALENT_INFORMATION_KEY.NO_TALENT_INFORMATION,
            ),
          ),
        );
      }
    }
  };

  useEffect(() => {
    setFieldsValue();
  }, [editable, isApprovedRequestToEdit]);

  useEffect(() => {
    if (isAssessmentPatchSuccess) {
      perfMessage.success(successMessage);
      dispatch(resetSendRequestData());
    }
  }, [isAssessmentPatchSuccess]);

  useEffect(() => {
    if (isAssessmentPostSuccess) {
      clearInputValue();
      perfMessage.success(successMessageForPostAssessmentByTBP);
      history.push({
        pathname: MENU_PAGE_LINK.NON_ENGAGED_CYCLES_PAGE,
        state: { isFromNonEngagedCycleDetail: true },
      });
      dispatch(setIsEditRatingSummary(false));
      dispatch(resetSendRequestData());
    }
    clearConfirmModal();
  }, [isAssessmentPostSuccess]);

  useEffect(() => {
    if (!isAssessmentPostLoading) {
      clearConfirmModal();
    }
  }, [isAssessmentPostLoading]);

  useEffect(() => {
    if (isNonEngagedCyclesPage) {
      dispatch(setIsEditRatingSummary(false));
      clearInputValue();
    }
  }, [isNonEngagedCyclesPage]);

  const onSubmitRatingSummary = async values => {
    if (isNonEngagedCyclesPage) {
      dispatch(setConfirmModalStatus(true));
    } else {
      const hideModal = perfModalConfirm({
        centered: true,
        width: 600,
        content: content,
        okText: okText,
        cancelText: cancelText,
        onOk: async () => {
          isPerformancePartner &&
            isDoneCycle &&
            trackEvent({
              category: TWER_I_SUPPORT_BY_PP_API.category,
              action: TWER_I_SUPPORT_BY_PP_API.action.PP_EDITS_DONE_CYCLE_AFTER_REQUEST_APPROVED,
            });
          if (!isNonEngagedCyclesPage && cycleVersion === CYCLE_VERSION.OLD) {
            await updateReviewByReviewer(cycleId, {
              ...values,
              summary: summaryState,
              additionalTalentInformation,
            }).finally(() => {
              hideModal();
              perfMessage.success(successMessage);
            });
          }
          if (!isNonEngagedCyclesPage && cycleVersion === CYCLE_VERSION.NEW) {
            dispatch(
              patchAssessmentByPPAsync({
                cycleId: cycleId,
                assessment: {
                  rating: selectedRating || assessment.rating,
                  summary: summaryState?.trim() || assessment.summary,
                  additionalTalentInformation: !isEmpty(additionalTalentInformation)
                    ? additionalTalentInformation
                    : assessment.additionalTalentInformation,
                },
              }),
            );
            hideModal();
          }
          clearInputValue();
          onSave && onSave();
        },
      });
    }
  };

  const onSaveDraftSuccess = () => {
    const matomoDraftAction =
      (isOpenCycle && TWER_I_SUPPORT_BY_PP_API.action.PP_ADDS_DRAFT_ASSESSMENT_SUMMARY) ||
      (isDraftCycle && TWER_I_SUPPORT_BY_PP_API.action.PP_EDITS_DRAFT_ASSESSMENT_SUMMARY);

    isPerformancePartner &&
      trackEvent({
        category: TWER_I_SUPPORT_BY_PP_API.category,
        action: matomoDraftAction,
      });

    trigSurvey(WRITE_ASSESSMENT);
    perfMessage.success(ratingSummaryEditorLocale.saveDraftSuccessMessage);
    clearInputValue();

    onSave && onSave();
  };

  const onUpdateAssessmentSuccess = () => {
    perfMessage.success(ratingSummaryEditorLocale.updateAssessmentSuccessMessage);
    clearInputValue();
    onSave && onSave();
  };

  const onSaveDraftV1 = async values => {
    setIsSaveDraftLoading(true);
    await updateReviewByReviewer(cycleId, {
      ...values,
      summary: summaryState,
      targetStatus: CYCLE_STATUS_ENUM.DRAFT,
      additionalTalentInformation,
    }).finally(() => {
      setIsSaveDraftLoading(false);
    });
    onSaveDraftSuccess();
  };

  const onSaveDraftOrUpdateForCreateBySystem = values => {
    dispatch(
      putPPSaveAssessmentAsync({
        cycleId,
        summary: summaryState,
        additionalTalentInformation,
        rating: get(values, 'rating'),
        operationType: isPpUpdateAssessment
          ? PP_SAVE_ASSESSMENT_VALUE.UPDATE_ASSESSMENT
          : PP_SAVE_ASSESSMENT_VALUE.SAVE_AS_DRAFT,
      }),
    );
  };

  useEffect(() => {
    if (ppSaveAssessmentSuccess) {
      if (operationType === PP_SAVE_ASSESSMENT_VALUE.SAVE_AS_DRAFT) {
        onSaveDraftSuccess();
      }
      if (operationType === PP_SAVE_ASSESSMENT_VALUE.UPDATE_ASSESSMENT) {
        onUpdateAssessmentSuccess();
      }
      dispatch(setPpSaveAssessment({ success: false }));
    }
  }, [ppSaveAssessmentSuccess]);

  const onSaveDraft = values => {
    if (createBySystem) {
      onSaveDraftOrUpdateForCreateBySystem(values);
    } else {
      onSaveDraftV1(values).then();
    }
  };

  const cancelEditDoneCycle = () => {
    window.location.reload();
  };

  const onCancelOperation = () => {
    if (isApprovedRequestToEdit) {
      perfModalConfirm({
        centered: true,
        content: confirmUndoModalLocale.confirmMessage,
        okText: confirmUndoModalLocale.okText,
        cancelText: confirmUndoModalLocale.cancelText,
        onOk: () => {
          cancelEditDoneCycle();
        },
      });
      return;
    }
    if (!isNonEngagedCyclesPage && showConfirmModal()) {
      perfModalConfirm({
        centered: true,
        title: confirmCancelModalLocale.title,
        content: confirmCancelModalLocale.confirmMessage,
        okText: confirmCancelModalLocale.okText,
        cancelText: confirmCancelModalLocale.cancelText,
        onOk: () => {
          clearInputValue();
          onCancel();
        },
      });
    } else {
      onCancel();
    }
    if (isNonEngagedCyclesPage && isEditByTbp) {
      if (showConfirmModal()) {
        dispatch(setConfirmModalStatus(true));
        dispatch(setConfirmModalType('cancelEditAssessmentNonEngaged'));
      } else {
        dispatch(setIsEditRatingSummary(false));
        scrollToTop();
      }
    }
  };

  const onSelectRating = selectedRating => {
    dispatch(setSelectedRating(selectedRating));
  };

  const updateSummaryHtml = html => {
    dispatch(setSummaryState(html));
  };

  const updateSummaryText = text => {
    setSummaryTextInfo(text);
  };

  const onDropdownVisibleChange = () => {
    setIsDropdownVisible(prev => !prev);
  };

  const onCheckNoTalent = event => {
    dispatch(setIsCheckedHP(false));
    dispatch(setIsCheckedGC(false));
    dispatch(setIsCheckedNoTalent(event.target.checked));
  };

  const onCheckHPTag = event => {
    dispatch(setIsCheckedHP(event.target.checked));
    dispatch(setIsCheckedNoTalent(false));
  };

  const onCheckGCTag = event => {
    dispatch(setIsCheckedGC(event.target.checked));
    dispatch(setIsCheckedNoTalent(false));
  };

  const onCloseHPTag = () => {
    dispatch(setIsCheckedHP(false));
  };

  const onCloseGCTag = () => {
    dispatch(setIsCheckedGC(false));
  };

  const talentLocale = ratingSummaryEditorLocale.talentInformation;
  const items = [
    {
      label: (
        <Checkbox className="talent-checkbox" checked={isCheckedHP} onChange={onCheckHPTag}>
          <span className="tags">{talentLocale.hp}</span>
          <span>{talentLocale.hpCheckbox}</span>
          <Tooltip placement="rightTop" title={talentLocale.hpTip}>
            <img src={InformationIcon} alt="" />
          </Tooltip>
        </Checkbox>
      ),
      key: '0',
    },
    {
      label: (
        <Checkbox className="talent-checkbox" checked={isCheckedGC} onChange={onCheckGCTag}>
          <span className="tags">{talentLocale.gc}</span>
          <span>{talentLocale.gcCheckbox}</span>
          <Tooltip placement="rightTop" title={talentLocale.gcTip}>
            <img src={InformationIcon} alt="" />
          </Tooltip>
        </Checkbox>
      ),
      key: '1',
    },
  ];

  const renderTalentTagsRow = () => {
    const talentLocale = ratingSummaryEditorLocale.talentInformation;
    return (
      <div className="talent-row-container">
        <div className="talent-title">{talentLocale.label}</div>
        <Tooltip
          arrow={true}
          placement="bottomLeft"
          title={talentLocale.tagsTip}
          overlayClassName="talent-tooltip"
        >
          <img src={InformationIcon} alt="" style={{ height: '30px', width: '18px' }} />
        </Tooltip>
        <Dropdown
          menu={{ items }}
          placement="bottomLeft"
          className={cls('talent-dropdown', {
            'talent-dropdown-hover': isDropdownVisible,
            'talent-dropdown-disabled': isPpUpdateAssessment,
          })}
          overlayClassName="talent-dropdown-menu"
          open={isDropdownVisible}
          onOpenChange={onDropdownVisibleChange}
          disabled={isPpUpdateAssessment}
        >
          <div>{talentLocale.dropdownButton}</div>
        </Dropdown>
        {!isCheckedHP && !isCheckedGC && (
          <Checkbox
            className="no-talent-checkbox"
            checked={isCheckedNoTalent}
            onChange={onCheckNoTalent}
            disabled={isPpUpdateAssessment}
          >
            {talentLocale.noTalentCheckbox}
          </Checkbox>
        )}
        {isCheckedHP && (
          <Alert
            className={cls({
              'talent-tag-disabled': isPpUpdateAssessment,
            })}
            closable={!isPpUpdateAssessment}
            type="success"
            message={talentLocale.hpCheckbox}
            onClose={onCloseHPTag}
          />
        )}
        {isCheckedGC && (
          <Alert
            className={cls({
              'talent-tag-disabled': isPpUpdateAssessment,
            })}
            closable={!isPpUpdateAssessment}
            type="success"
            message={talentLocale.gcCheckbox}
            onClose={onCloseGCTag}
          />
        )}
      </div>
    );
  };

  const renderAssessmentArea = () => {
    if (isProbation || cycleVersion === CYCLE_VERSION.OLD) {
      return (
        <Form.Item name="summary" label={ratingSummaryEditorLocale.summary.label}>
          <TinyMceEditor
            initialValue={summaryInitialValue}
            updateHtmlInfo={updateSummaryHtml}
            updateTextInfo={updateSummaryText}
            maxLength={SUMMARY_MAX_LENGTH}
            placeholder={ratingSummaryEditorLocale.summary.placeholder}
          />
        </Form.Item>
      );
    }
    return (
      <Form.Item name="summary">
        <div className="summary-description">
          <div className="label">{ratingSummaryEditorLocale.summary.label}</div>
          <div
            className="hint"
            dangerouslySetInnerHTML={{ __html: ratingSummaryEditorLocale.summary.hint }}
          ></div>
          <div
            className="tips"
            dangerouslySetInnerHTML={{ __html: ratingSummaryEditorLocale.summary.tips }}
          ></div>
        </div>
        <TinyMceEditor
          initialValue={summaryInitialValue}
          updateHtmlInfo={updateSummaryHtml}
          updateTextInfo={updateSummaryText}
          maxLength={SUMMARY_MAX_LENGTH}
          placeholder={ratingSummaryEditorLocale.summary.placeholder}
        />
      </Form.Item>
    );
  };

  const formLoading = useMemo(() => {
    return isSaveDraftLoading || ppSaveAssessmentLoading || isAssessmentPostLoading;
  }, [isSaveDraftLoading, ppSaveAssessmentLoading, isAssessmentPostLoading]);

  return (
    <>
      {!editable ? (
        <RatingSummaryPreview
          locale={reviewListLocale}
          onEdit={onEdit}
          isExpand={isExpand}
          showEditIcon={showEditIcon}
          isShowRequestEditButton={isShowRequestEditButton}
          isShowDoneReviewEditButton={isShowDoneReviewEditButton}
          onOpenRequestEditModal={onOpenRequestEditModal}
        />
      ) : (
        <LoadingWrapper loading={formLoading}>
          <Form
            className="rating-summary-editor"
            layout="vertical"
            onFinish={isDoneCycle ? onSubmitRatingSummary : onSaveDraft}
            form={form}
          >
            <CustomPrompt
              when={
                showConfirmModal() &&
                !isSubmitModalVisible &&
                !isAssessmentPostSuccess &&
                !ppSaveAssessmentSuccess
              }
            />
            <Form.Item
              name="rating"
              label={ratingSummaryEditorLocale.rating.label}
              rules={[
                {
                  required: true,
                  message: ratingSummaryEditorLocale.rating.requiredMessage,
                },
              ]}
            >
              <Select
                onChange={onSelectRating}
                placeholder={ratingSummaryEditorLocale.rating.placeholder}
                suffixIcon={<CaretDownOutlined />}
                disabled={isApprovedRequestToEdit || isPpUpdateAssessment}
              >
                {Object.values(ratingSelectedValue()).map((rating, index) => (
                  <Option key={rating} value={rating} disabled={isNoneEngaged && index === 0}>
                    {rating}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {isProbation !== PROBATION_STATUS.UNDER_PROBATION && (
              <div className="rating-description">
                {createBySystem
                  ? CYCLE_SCORE_RATING_DESCRIPTION[selectedRating]
                  : CYCLE_SCORE_RATING_DESCRIPTION_V1[selectedRating]}
              </div>
            )}
            {renderTalentTagsRow()}
            {renderAssessmentArea()}
            <Form.Item shouldUpdate>
              {() => (
                <div className="operation">
                  <IconButton
                    className="cancel"
                    onClick={onCancelOperation}
                    buttonText={cancelButtonText()}
                    iconClassName={'ri-close-circle-fill'}
                  />
                  <Tooltip
                    placement="topLeft"
                    {...saveButtonProps()}
                    title={
                      <span className="save-draft-button-tooltip">
                        Please check if you have filled:
                        <br />
                        <ul>
                          <li>Performance Indicator</li>
                          <li>Additional Talent Information</li>
                          <li>Assessment Summary</li>
                        </ul>
                      </span>
                    }
                  >
                    <IconButton
                      className="save"
                      htmlType="submit"
                      disabled={!canSubmit()}
                      type={isDoneCycle || isPpUpdateAssessment ? 'primary' : 'default'}
                      buttonText={confirmButtonText()}
                      iconClassName={'ri-save-fill'}
                    />
                  </Tooltip>
                </div>
              )}
            </Form.Item>
          </Form>
        </LoadingWrapper>
      )}
      {isNonEngagedCyclesPage && <ConfirmModal {...confirmModalProps()} />}
    </>
  );
};

export default RatingSummaryEditor;
