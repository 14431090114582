import ArrowUpdate from '@@/assets/images/arrow-update.svg';
import UpdateTime from '@@/assets/images/update-time-background.svg';
import { UPDATE_VERSION_TIME } from '@@/common/constant/dashboard';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { WORDING_CHANGE_VIDEO_LINK } from '../../constant/review';
import { scrollToTop } from '@@/_new_src_/utils/feature';
import './index.less';

const ProductUpdateModal = props => {
  const { onClose, isChecked = false, locale, onModalClose } = props;
  const [isShowCloseAnimation, setIsShowCloseAnimation] = useState(false);
  const maskRef = useRef(null);
  const modalRef = useRef(null);

  const closeModal = () => {
    if (isChecked) {
      return onClose();
    }
    scrollToTop();
    setIsShowCloseAnimation(true);
    return true;
  };

  useEffect(() => {
    scrollToTop();
    document.body.classList.add('fix');
    return () => {
      document.body.classList.remove('fix');
    };
  }, []);

  useEffect(() => {
    if (modalRef?.current) {
      modalRef.current.addEventListener('animationend', e => {
        if (e.animationName === 'closeAnimationModal') {
          onModalClose();
        }
      });
    }
  }, [onModalClose]);

  useEffect(() => {
    if (maskRef?.current) {
      maskRef.current.addEventListener('animationend', () => {
        onClose();
      });
    }
  }, [onClose]);

  return (
    <div className={`product-update-modal ${isShowCloseAnimation ? 'close-animation' : ''}`}>
      <div className="mask" ref={maskRef} />
      <div className="modal" ref={modalRef}>
        <div className="modal-background" />
        <div className="close-button" onClick={closeModal}>
          <CloseOutlined />
        </div>
        <div className="header">
          <div className="update-time">
            <img src={UpdateTime} alt="" />
            <div className="date">{UPDATE_VERSION_TIME}</div>
          </div>
        </div>
        <div className="header-title">{locale.headerTitle}</div>
        <div className="content">
          <div className="item">
            <div className="index">1</div>
            <div className="title">{locale.firstTitle}</div>
            <div className="item-content">
              <div className="col col-1 col-old">
                <div>{locale.Reviewer}</div>
                <div>{locale.Contributor}</div>
                <div>{locale.Reviewee}</div>
                <div>{locale.rating}</div>
              </div>
              <div className="col col-2 col-arrow">
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
              </div>
              <div className="col col-3 col-new">
                <div>{locale.PerformancePartner}</div>
                <div>{locale.AdditionalPartner}</div>
                <div>{locale.TWer}</div>
                <div>{locale.performanceIndicator}</div>
              </div>
              <div className="col col-4 col-old">
                <div>{locale.Review}</div>
                <div>{locale.SelfReview}</div>
                <div>{locale.ReviewInput}</div>
                <div>{locale.summaryOfWhy}</div>
              </div>
              <div className="col col-5 col-arrow">
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
                <div>
                  <img src={ArrowUpdate} alt="" />
                </div>
              </div>
              <div className="col col-6 col-new">
                <div>{locale.PerformanceCycle}</div>
                <div>{locale.SelfAssessment}</div>
                <div>{locale.AssessmentInput}</div>
                <div>{locale.assessmentSummary}</div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="index">2</div>
            <div className="title">
              {locale.secondTitleFirst}
              <b>{locale.secondTitleAdditional}</b>
              {locale.additionalPartners}
            </div>
            <div className="item-content second-content">{locale.secondContent}</div>
          </div>
        </div>
        <div className="footer">
          <Button
            className="cancel"
            onClick={() => {
              window.open(WORDING_CHANGE_VIDEO_LINK, '_blank');
            }}
          >
            {locale.watchDemo}
          </Button>
          <div className="skip" onClick={closeModal}>
            {locale.skipNow}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductUpdateModal;
